import React from "react";
import { graphql, Link, StaticQuery } from 'gatsby';
import styled from '@emotion/styled'
import dimensions from "styles/dimensions";

const LogoLink = styled(Link)`
  img {
    width: 19.6rem;
    user-select: none;
  }
  
  @media(min-width: ${dimensions.desktopTransitionUp}px) {
    img {
      width: 29.2rem;
    }
  }
`;

const Logo = props => {
  const data = props.data.allPrismicHeader.edges[0].node.data;

  if (!data) return null;

  return (
    <LogoLink to="/">
      <img src={data.logo.url} alt="Leading Edge: Alliance for Excellence in Jewish Leadership" />
    </LogoLink>
  )

}

const LogoComponent = (props) => (
  <StaticQuery
    query={graphql`
      {
        allPrismicHeader {
          edges {
            node {
              data {
                logo {
                  url
                }
              }
            }
          }
        }
      }
    `}
    render={data => <Logo data={data} {...props} />}
  />
);

export default LogoComponent;