//theme colors and tints (dark900 to normal500 to light100)
const colors = {
    grey900: "#272828",
    grey800: "#484848",
    grey700: "#545555",
    grey600: "#777777",
    grey500: "#BEBFC0",
    grey400: "#D8D8D8",
    grey300: "#E9EAEA",
    grey200: "#F2F2F2",
    grey100: "#F6F6F6",

    orange300: "#C95427",
    orange500: "#FF7F02",

    yellow500: "#FCAF17",
    yellow400: "#FFCB21",

    teal900: "#383D3D",
    teal800: "#445866",
    teal700: "#2E6664",
    teal600: "#3F7E7C",
    teal300: "#E1F9F8",
    teal100: "#F8FAFB",

    purple800: "#655483",
}

export default colors;
