import React from "react";
import { graphql, StaticQuery } from 'gatsby';
import styled from '@emotion/styled'
import colors from "styles/colors";
import dimensions from "styles/dimensions";
import Facebook from "vectors/facebook.svg";
import Linkedin from "vectors/linkedin.svg";
import Twitter from "vectors/twitter.svg";
import Instagram from "vectors/instagram.svg";

const SocialBarList = styled.ul`
  display: inline-block;
  
  li {
    display: inline-block;
    position: relative;
    top: 0.3rem;
    
    & + * {
      margin-left: 1.6rem;
    }
  }
  
  a {
    &:hover {
      svg {
        fill: ${colors.orange500};
      }
    }
  }
  
  svg {
    display: block;
    height: 1.8rem;
    width: 1.8rem;
    fill: ${colors.grey800};
    transition: fill 0.12s ease-in-out;
  }
  
  @media(min-width: ${dimensions.tabletLandscapeUp}px) {
    li {
      & + * {
        margin-left: 2.4rem;
      }
    }
  }
`;

const SocialBar = props => {
  const data = props.data.allPrismicHeader.edges[0].node.data;

  if (!data) return null;

  return (
    <SocialBarList>
      {(data.twitter_url && data.twitter_url.url) && (
        <li>
          <a
            href={data.twitter_url.url}
            target="blank"
            rel="noopener noreferrer"
          >
            <Twitter />
          </a>
        </li>
      )}

      {(data.instagram_url && data.instagram_url.url) && (
        <li>
          <a
            href={data.instagram_url.url}
            target="blank"
            rel="noopener noreferrer"
          >
            <Instagram />
          </a>
        </li>
      )}

      {(data.facebook_url && data.facebook_url.url) && (
        <li>
          <a
            href={data.facebook_url.url}
            target="blank"
            rel="noopener noreferrer"
          >
            <Facebook />
          </a>
        </li>
      )}

      {(data.linkedin_url && data.linkedin_url.url) && (
        <li>
          <a
            href={data.linkedin_url.url}
            target="blank"
            rel="noopener noreferrer"
          >
            <Linkedin />
          </a>
        </li>
      )}
    </SocialBarList>
  )
}

const SocialComponent = (props) => (
  <StaticQuery
    query={graphql`
      {
        allPrismicHeader {
          edges {
            node {
              data {
                facebook_url {
                  url
                }
                instagram_url {
                  url
                }
                twitter_url {
                  url
                }
                linkedin_url {
                  url
                }
              }
            }
          }
        }
      }
    `}
    render={data => <SocialBar data={data} {...props} />}
  />
);

export default SocialComponent;