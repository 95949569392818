import React from 'react';
import { graphql, StaticQuery, Link } from 'gatsby';
import styled from '@emotion/styled'
import colors from 'styles/colors';
import dimensions from 'styles/dimensions';
import Facebook from 'vectors/facebook.svg';
import Linkedin from 'vectors/linkedin.svg';
import Twitter from 'vectors/twitter.svg';
import Instagram from 'vectors/instagram.svg';
import { Inner } from 'styles/structure';
import Arrow from 'vectors/caret-down.svg';
import ArrowLink from 'components/_ui/ArrowLink';
import ContactFooter from 'components/ContactFooter';

const FooterContainer = styled.div`
  background-color: ${colors.grey100};
  padding: 5.6rem 0 2.4rem;

  @media (min-width: ${dimensions.tabletLandscapeUp}px) {
    padding-top: 4rem;
  }

  a {
    transition: color 0.08s ease-in-out;

    &:hover { 
      color: ${colors.orange500};
    }
  }
`;

const FooterNav = styled.div`
  .is-open {
    svg {
      transform: translateY(-50%) rotate(180deg);
    }

    ul {
      display: block;
    }
  }

  @media (min-width: ${dimensions.tabletLandscapeUp}px) {
    display: flex;
    justify-content: space-between;
  }
`;

const FooterBlocks = styled.div`
  border-bottom: 1px solid ${colors.grey400};

  & + * {
    margin-top: 4rem;
  }

  @media (min-width: ${dimensions.tabletLandscapeUp}px) {
    display: flex;
    border-bottom: 0;

    & + * {
      margin-top: 0;
    }
  }
`;

const FooterNavBlock = styled.div`
  h6 {
    color: ${colors.grey900};
  }

  ul {
    padding-bottom: 2.4rem;
    display: none;
  }

  li {
    & + * {
      margin-top: 0.8rem;
    }
  }

  a {
    color: ${colors.grey900};
    font-size: 1.6rem;
  }

  .career-hub {
    display: none;
    padding: 0;
  }

  .career-hub a {
    padding: 0;
  }

  @media (min-width: ${dimensions.tabletLandscapeUp}px) {
    .career-hub {
      border-top: solid 1px ${colors.grey400};
      display: block;
      padding-top: 3.2rem;
    }

    .career-hub a {
      padding-top: 3.2rem;
    }

    margin-right: 7.77vw;

    h6 {
      font-size: 1.4rem;
    }

    ul {
      display: block;
    }

    a {
      font-size: 1.4rem;
    }
  }

  @media (min-width: ${dimensions.desktopLargeUp}) {
    margin-right: 10.4rem;
  }
`;

const FooterNavHeader = styled.div`
  position: relative;
  padding: 1.6rem 0;
  user-select: none;
  border-top: solid 1px ${colors.grey400};

  svg {
    position: absolute;
    top: 50%;
    right: 0;
    margin-right: 4rem;
    width: 1.6rem;
    height: 1.1rem;
    fill: ${colors.grey600};
    transform: translateY(-50%);
    transform-origin: center center;
    transition: transform 0.12s ease-in-out, fill 0.08s ease-in-out;
  }

  @media (min-width: ${dimensions.tabletLandscapeUp}px) {
    border-top: 0;

    svg {
      display: none;
    }
  }
`;

const Contact = styled.div`
  @media (min-width: ${dimensions.tabletLandscapeUp}px) {
    position: absolute;
    right: 0;
    padding-right: 5vw;
  }
`;

const SocialIcons = styled.ul`
  display: flex;

  svg {
    display: block;
    height: 1.8rem;
    width: 1.8rem;
    fill: ${colors.grey800};
    transition: fill 0.12s ease-in-out;
  }

  li {
    & + * {
      margin-left: 2.4rem;
    }
  }

  a {
    &:hover {
      svg {
        fill: ${colors.orange500};
      }
    }
  }

  @media (min-width: ${dimensions.tabletLandscapeUp}px) {
    position: absolute;
    right: 5vw;
  }
`;

const FooterBottom = styled.div`
  @media (min-width: ${dimensions.tabletLandscapeUp}px) {
    display: flex;
    justify-content: space-between;
    margin-top: 4rem;
  }
`;

const FooterColophon = styled.div`
  margin-top: 8rem;
  font-size: 1.4rem;
  color: ${colors.grey700};

  a {
    color: ${colors.grey900};
    transition: color 0.08s ease-in-out;

    &:hover {
      color: ${colors.orange500};
    }

    &::before {
      content: '|';
      margin: 0 0.8rem;
    }
  }

  @media (min-width: ${dimensions.tabletLandscapeUp}px) {
    margin-top: 0;
  }
`;

const Ronik = styled.small`
  display: block;
  margin-top: 0.4rem;
  font-size: 1.4rem;
  color: ${colors.grey700};

  a {
    color: ${colors.grey900};
    transition: color 0.08s ease-in-out;

    &:hover {
      color: ${colors.orange500};
    }
  }

  @media (min-width: ${dimensions.tabletLandscapeUp}px) {
    position: absolute;
    right: 5vw;
  }
`;

class Footer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openList: [],
      data: [],
    };
  }

  showList = list => {
    let newArr = this.state.openList;
    newArr.push(list);
    this.setState({
      openList: newArr,
    });
  };

  hideList = list => {
    let newArr = this.state.openList;
    newArr.splice(newArr.indexOf(list), 1);
    this.setState({
      openList: newArr,
    });
  };

  render() {
    const data = this.props.data.allPrismicFooter.edges[0].node.data;
    const socialData = this.props.data.allPrismicHeader.edges[0].node.data;

    let self = this;

    return (
      <>
        <ContactFooter
          heading={data.contact_heading}
          text={data.contact_text}
        />

        <FooterContainer>
          <Inner>
            <FooterNav>
              <FooterBlocks>
                {data.body.map((block, i) => (
                  <FooterNavBlock
                    key={`footer-block-${i}`}
                    className={self.state.openList.includes(i) && 'is-open'}
                  >
                    <FooterNavHeader
                      onClick={() =>
                        self.state.openList.includes(i)
                          ? self.hideList(i)
                          : self.showList(i)
                      }
                    >
                      <h6>{block.primary.header}</h6>

                      <Arrow className="down-arrow" />
                    </FooterNavHeader>

                    <ul>
                      {block.items.map((link, j) => (
                        <li key={`footer-block-${i}-item-${j}`}>
                          <Link to={link.url}>{link.link_title}</Link>
                        </li>
                      ))}
                    </ul>
                  </FooterNavBlock>
                ))}
              </FooterBlocks>
              <Contact>
                <SocialIcons>
                  {(socialData.twitter_url && socialData.twitter_url.url) && (
                    <li>
                      <a
                        href={`${socialData.twitter_url.url}`}
                      >
                        <Twitter />
                      </a>
                    </li>
                  )}

                  {(socialData.instagram_url && socialData.instagram_url.url) && (
                    <li>
                      <a
                        href={`${socialData.instagram_url.url}`}
                      >
                        <Instagram />
                      </a>
                    </li>
                  )}

                  {(socialData.facebook_url && socialData.facebook_url.url) && (
                    <li>
                      <a
                        href={`${socialData.facebook_url.url}`}
                      >
                        <Facebook />
                      </a>
                    </li>
                  )}

                  {(socialData.linkedin_url && socialData.linkedin_url.url) && (
                    <li>
                      <a
                        href={`${socialData.linkedin_url.url}`}
                      >
                        <Linkedin />
                      </a>
                    </li>
                  )}
                </SocialIcons>

                <ArrowLink text="Contact Us" destination="/contact-us" variant="footer" />
              </Contact>
            </FooterNav>

            <FooterBottom>
              <FooterColophon>
                <small>©{new Date().getFullYear()} Leading Edge</small>

                {data.tertiary_links.length > 0 && data.tertiary_links[0].simple_page && data.tertiary_links.map((link, i) => (
                  <Link key={`tertiary-link${i}`} to={`/page/${link.simple_page.document.uid}`}>{link.simple_page.document.data.title.text}</Link>
                ))}

              </FooterColophon>

              <Ronik>
                Built by{' '}
                <a
                  href="https://www.ronikdesign.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Ronik
                </a>
              </Ronik>
            </FooterBottom>
          </Inner>
        </FooterContainer>
      </>
    );
  }
}

const FooterComponent = (props) => (
  <StaticQuery
    query={graphql`
      {
        allPrismicHeader {
          edges {
            node {
              data {
                facebook_url {
                  url
                }
                instagram_url {
                  url
                }
                twitter_url {
                  url
                }
                linkedin_url {
                  url
                }
              }
            }
          }
        }
        allPrismicFooter {
          edges {
            node {
              data {
                contact_heading
                contact_text
                tertiary_links {
                  simple_page {
                    document {
                      ... on PrismicSimplePage {
                        uid
                        data {
                          title {
                            text
                          }
                        }
                      }
                    }
                  }
                }
                body {
                  ... on PrismicFooterBodyList {
                    primary {
                      header
                    }
                    items {
                      link_title
                      url
                    }
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={data => <Footer data={data} {...props} />}
  />
);

export default FooterComponent;