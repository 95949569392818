import css from '@emotion/css';
import styled from '@emotion/styled'
import dimensions from "styles/dimensions";
import colors from "styles/colors";

const typeStyles = css`
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  a,
  p,
  span,
  li,
  div,
  input,
  textarea,
  button {
    font-family: "Basis Grotesque", sans-serif;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: ${colors.grey900};  
  }

  a,
  p,
  span,
  li,
  div,
  input,
  textarea,
  button {
    color: ${colors.grey800};
  }
  
  
  h1 {
    font-size: 3.6rem;
    line-height: 1.25;
    font-weight: 600;
    
    @media(min-width: ${dimensions.tabletLandscapeUp}px) {
      font-size: 5.6rem;
      line-height: 1.2;
    }
  }
  
  h2 {
    font-size: 3.2rem;
    line-height: 1.25;
    font-weight: 600;
    
    @media(min-width: ${dimensions.tabletLandscapeUp}px) {
      font-size: 4.4rem;
      line-height: 1.2;
    }
  }
  
  h3 {
    font-size: 2.8rem;
    line-height: 1.25;
    font-weight: 600;
    
    @media(min-width: ${dimensions.tabletLandscapeUp}px) {
      font-size: 3.2rem;
      line-height: 1.32;
    }
  }
  
  h4 {
    font-size: 2.4rem;
    line-height: 1.41;
    font-weight: 600;
  }
  
  h5 {
    font-size: 2.1rem;
    line-height: 1.33;
    font-weight: 500;
    
    @media(min-width: ${dimensions.tabletLandscapeUp}px) {
      font-size: 2.4rem;
      line-height: 1.41;
    }
  }
  
  h6 {
    font-size: 1.8rem;
    line-height: 1.33;
    font-weight: 600;
    
    @media(min-width: ${dimensions.tabletLandscapeUp}px) {
      font-size: 2.1rem;
    }
  }
  
  p {
    color: ${colors.grey800};
    font-size: 1.6rem;
    line-height: 1.44;
    
    @media(min-width: ${dimensions.tabletLandscapeUp}px) {
      &.is-large {
        font-size: 2.1rem;
      }
    }
  }
  
  a {
    &:hover {
      cursor: pointer;
    }
  }
`

export const Rubric = styled.span`
  display: block;
  font-size: 1.4rem;
  
  & + * {
    margin-top: 0.8rem;
  }
  
  span {
    color: ${colors.teal600};
    font-size: 1.6rem;
    font-weight: 500;
    display: inline-block;
    
    & + * {
      &::before {
        content: '•';
        margin: 0 0.4rem;
      }
    }
  }
`

export const Deck = styled.h4`
  font-weight: 400;
  line-height: 1.44;
  
  & + * {
    margin-top: 3.2rem;
  }
  
  @media(min-width: ${dimensions.tabletLandscapeUp}px) {
    & + * {
      margin-top: 6.4rem;
    }
  }
`

export default typeStyles;
