import React from "react";
import { graphql, StaticQuery, Link } from 'gatsby';
import styled from '@emotion/styled'
import colors from "styles/colors";
import dimensions from "styles/dimensions";
import { Inner } from 'styles/structure';
import Fade from 'react-reveal/Fade';

const PanelContainer = styled.div`
  display: none;
  position: relative;
  background-color: #fff;
  transition: margin 0.24s ease-in;
  margin-top: -100vh;
  max-height: calc(100vh - (10.4rem));
  overflow-y: scroll;
  box-shadow: 0 0.3rem 1.4rem rgba(0,0,0,0.08);
  
  .has-open-panel & {
    margin-top: 0;
    transition: margin 0.24s ease-out;
  }
  
  @media(min-width: ${dimensions.desktopTransitionUp}px) {
    display: block;
  }
`;

const PanelSection = styled.div`
  display: flex;
  
  a {
    flex: 0 1 calc(50% - 2vw);
    color: #fff;
    transition: background 0.12s ease-in-out;
    
    &:nth-of-type(n+3) {
      margin-top: 4rem;
    }
    
    &:nth-of-type(odd) {
      margin-right: 4vw;
    }
    
    &:hover {
      h5 {
        color: ${colors.yellow500};
      }
    }
  }
  
  h5 {
    transition: color 0.12s ease-in-out;
    
    & + * {
      margin-top: 0.8rem;
    }
  }
`;

const PanelHeading = styled.div`
  position: relative;
  max-width: 40.6rem;
  flex: 1 1 46rem;
  padding: 4.8rem 8rem 4.8rem 0;
  background-color: ${colors.grey100};
  
  &::before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    right: 100%;
    height: 100%;
    width: 50vw;
    background-color: ${colors.grey100};
  }
  
  h3 {
    font-weight: 500;
  }
`;

const PanelWrap = styled.div`
  flex: 1 1 66.667%;
  padding: 4.8rem 0 4.8rem 9.6rem;
  height: 100%;
  
  header {
    margin-bottom: 3.2rem;
    
    a {
      color: ${colors.grey900};
    }
  }
`;

const PanelContent = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const PanelLink = styled(Link)`
  position: relative;
  display: inline-block;
  font-size: 2rem;
  padding-bottom: 0.4rem;
  
  &::after {
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 0.2rem;
    background-color: ${colors.grey300};
    transition: background 0.08s ease-in-out;
  }
  
  &:hover {
    &::after {
      background-color: ${colors.yellow500};
    }
  }
`;

const Panel = props => {
  const data = props.data.allPrismicHeader.edges[0].node.data

  if (!data) return null;

  return (
    <PanelContainer>
      <Inner>
        {props.current === 'programs' && (
          <Fade>
            <PanelSection>
              <PanelHeading>
                <h3>{data.programs_description}</h3>
              </PanelHeading>

              <PanelWrap>
                <PanelContent>
                  {data.programs.map((program, i) => (
                    <Link
                      key={i}
                      to={program.url}
                    >
                      <h5>{program.heading}</h5>
                      <p>{program.description}</p>
                    </Link>
                  ))}
                </PanelContent>
              </PanelWrap>
            </PanelSection>
          </Fade>
        )}

        {props.current === 'resources' && (
          <Fade>
            <PanelSection>
              <PanelHeading>
                <h3>{data.resources_description}</h3>
              </PanelHeading>

              <PanelWrap>
                <header>
                  <PanelLink to='/collections'>Collections</PanelLink>
                </header>

                <PanelContent>
                  {data.collections.map((collection, i) => (
                    <Link
                      key={i}
                      to={collection.url}
                    >
                      <h5>{collection.heading}</h5>
                      <p>{collection.description}</p>
                    </Link>
                  ))}
                </PanelContent>
              </PanelWrap>
            </PanelSection>
          </Fade>
        )}

        {props.current === 'about' && (
          <Fade>
            <PanelSection>
              <PanelHeading>
                <h3>{data.about_us_description}</h3>
              </PanelHeading>

              <PanelWrap>
                <PanelContent>
                  {data.about_pages.map((page, i) => (
                    <Link
                      key={i}
                      to={page.url}
                    >
                      <h5>{page.heading}</h5>
                      <p>{page.description}</p>
                    </Link>
                  ))}
                </PanelContent>
              </PanelWrap>
            </PanelSection>
          </Fade>
        )}

        {props.current === 'news' && (
          <Fade>
            <PanelSection>
              <PanelHeading>
                <h3>{data.news_description}</h3>
              </PanelHeading>

              <PanelWrap>
                <PanelContent>
                  {data.news_pages.map((page, i) => (
                    <Link
                      key={i}
                      to={page.url}
                    >
                      <h5>{page.heading}</h5>
                      <p>{page.description}</p>
                    </Link>
                  ))}
                </PanelContent>
              </PanelWrap>
            </PanelSection>
          </Fade>
        )}
      </Inner>
    </PanelContainer>
  )
}

const PanelComponent = (props) => (
  <StaticQuery
    query={graphql`
      {
        allPrismicHeader {
          edges {
            node {
              data {
                programs_description
                programs {
                  heading
                  description
                  url
                }
                resources_description
                collections {
                  heading
                  description
                  url
                }
                about_us_description
                about_pages {
                  heading
                  description
                  url
                }
                news_description
                news_pages {
                  heading
                  description
                  url
                }
              }
            }
          }
        }
      }
    `}
    render={data => <Panel data={data} {...props} />}
  />
);

export default PanelComponent;