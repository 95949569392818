import css from "@emotion/css";
import colors from 'styles/colors';
import dimensions from 'styles/dimensions';

const globalStyles = css`
  html {
    font-size: 62.5%;
    -webkit-font-smoothing: antialiased;
  }

  html,
  body,
  #root {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    min-height: 100%;
  }

  body {
    width: 100%;
    margin: 0 auto;
    font-size: 1rem;
    line-height: 1.5;
    color: ${colors.grey900};
    -webkit-font-smoothing: antialiased;
    
    &.is-locked {
      overflow: hidden !important;
    }

    * {
      box-sizing: border-box;

      &::selection {
          background: ${colors.orange500};
          color: white;
      }
    }
  }

  /*
  A workaround for forcing accessibility wrappers
  to have a 100% height.
  Reach Router issue here: https: //github.com/reach/router/issues/63
  */
  #___gatsby,
  div[role="group"][tabindex] {
    height: 100%;
    min-height: 100% !important;
  }
  
  /*
  A workaround to force hiding CookieBot attribution
  */
  #CybotCookiebotDialogPoweredByText,
  #CybotCookiebotDialogPoweredbyCybot { 
    display: none !important; 
  }
  
  @media(min-width: ${dimensions.tabletLandscapeUp}px) {
    body.is-locked {
      overflow: initial;
    }
  }
`

export default globalStyles;
