import React from "react";
import styled from '@emotion/styled'
import { Global } from "@emotion/react";
import resetStyles from "styles/reset";
import globalStyles from 'styles/global';
import typeStyles from 'styles/typography';
import Footer from "components/Footer";
import Header from "components/Header";
import ModalSignup from "components/ModalSignup";

const LayoutContainer = styled.div`
`;

const Layout = ({children}) => (
  <LayoutContainer>
    <Global styles={[resetStyles, globalStyles, typeStyles]}/>

    <div className="Layout">
      <Header/>

      <main className="Layout__content">
        {children}
      </main>

      <Footer/>
    </div>

    <ModalSignup />
  </LayoutContainer>
);

export default Layout;
