import React from 'react';
import { graphql, StaticQuery } from 'gatsby';
import styled from '@emotion/styled'
import dimensions from 'styles/dimensions';
import colors from 'styles/colors';
import RichText from 'components/RichText';
import MailchimpForm from 'components/MailchimpForm';
import Cookies from 'universal-cookie';
import { document } from 'browser-monads';
import Close from '../vectors/ex.svg';

const ModalContainer = styled.div`
  opacity: 0;
  visibility: hidden;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  z-index: 1000;
  transition: visibility 0s 0.4s;

  &::before {
    content: '';
    display: block;
    position: absolute;
    width: 100vw;
    height: 100vh;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    background-color: ${colors.teal900};
    z-index: -1;
    opacity: 0;
    transition: opacity 0.4s ease-in-out;
  }

  &.is-open {
    opacity: 1;
    visibility: visible;
    transition: visibility 0s 0s;

    &::before {
      opacity: 0.7;
    }
  }

  @media (min-width: ${dimensions.tabletLandscapeUp}px) {}
`;

const ModalCard = styled.div`
  background-color: #fff;
  width: calc(100vw - 4rem);
  max-width: 99.2rem;
  height: 86vh;
  max-height: 90rem;
  opacity: 0.6;
  transform: translateY(-8rem);
  transition: opacity 0.24s ease-in-out, transform 0.4s ease-in-out;
  padding: 6.4rem 4rem;
  overflow-y: scroll;
  
  .is-open & {
    opacity: 1;
    transform: translateY(0);
  }

  @media (min-width: ${dimensions.tabletLandscapeUp}px) {
    max-height: 67.2rem;
    display: flex;
    padding: 6.4rem 6.4rem 6.4rem 7.2rem;
  }
`;

const ModalImage = styled.div`
  display: none;
  position: relative;
  width: 32rem;
  height: 100%;
  flex: 0 0 32rem;
  margin-right: 5.6rem;
  
  &::before {
    content: '';
    display: block;
    position: absolute;
    z-index: -1;
    bottom: -2.4rem;
    left: -2.4rem;
    background-color: ${colors.yellow500};
    width: 88%;
    height: 50%;
  }
  
  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 10;
  }
  
  @media (min-width: ${dimensions.desktopUp}px) {
    display: block;
  }
`;

const ModalContent = styled.div`
  flex: 1 1 100%;
`;

const ModalHeader = styled.header`
  & + * {
    margin-top: 4rem;
  }
  
  h1 {
    & + * {
      margin-top: 1.6rem;
    }
  }
  
  p {
    max-width: 64rem;
  }

  @media (min-width: ${dimensions.tabletLandscapeUp}px) {
    & + * {
      margin-top: 4.8rem;
    }
  
    h1 {
      font-size: 3.2rem;
    }
  }
`;

const ModalButton = styled.button`
  appearance: none;
  display: flex;
  align-items: center;
  position: absolute;
  top: 2.4rem;
  right: 2.4rem;
  padding: 0.8rem;
  font-size: 1.6rem;
  font-weight: 400;
  cursor: pointer;

  svg {
    display: block;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    fill: ${colors.grey600};
    width: 1.3rem;
    height: 1.3rem;
  }
  
  span {
    display: inline-block;
    line-height: 1;
    padding-left: 2.1rem;
  }

  &:hover {
    background-color: #fff;
  }
  
  @media (min-width: ${dimensions.tabletLandscapeUp}px) {
    top: 3.2rem;
    right: 3.2rem;
  }
`;

class ModalSignup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
    };
  }

  handleClick = e => {
    if (this.node && this.node.contains(e.target)) {
      return;
    }

    this.closeModal();
  };

  closeModal = () => {
    this.setState({ isOpen: false });
    document.getElementsByTagName('body')[0].classList.remove('is-locked');
  };

  componentDidMount() {
    const cookies = new Cookies();
    const hasCookie = cookies.get('leadingedge_hasvisited');
    const self = this;

    document.addEventListener('mousedown', this.handleClick, false);

    if (!hasCookie) {
      setTimeout(function() {
        self.setState({ isOpen: true });
        document.getElementsByTagName('body')[0].classList.add('is-locked');

        cookies.set('leadingedge_hasvisited', true, { path: '/' });
      }, 4000);
    }
  }

  render() {
    const data = this.props.data.allPrismicMailingSignup.edges[0].node.data;

    if (!data) return null;

    return (
      <ModalContainer className={this.state.isOpen ? 'is-open' : ''} role="dialog">
        <ModalCard ref={node => (this.node = node)}>
          {data.image && (
            <ModalImage>
              <img src={data.image.url} alt={data.image.alt} />
            </ModalImage>
          )}

          <ModalContent>
            <ModalHeader>
              {data.heading && <RichText render={data.heading.raw} />}
              {data.text && <RichText render={data.text.raw} />}
            </ModalHeader>

            <MailchimpForm
              variant='modal'
            />

            <ModalButton
              onClick={() => {
                this.closeModal();
              }}
            >
              <Close />
              <span>Close</span>
            </ModalButton>
          </ModalContent>
        </ModalCard>
      </ModalContainer>
    );
  }
}

const ModalSignupComponent = (props) => (
  <StaticQuery
    query={graphql`
      {
        allPrismicMailingSignup {
          edges {
            node {
              data {
                heading {
                  raw
                }
                text {
                  raw
                }
                image {
                  url
                }
              }
            }
          }
        }
      }
    `}
    render={data => <ModalSignup data={data} {...props} />}
  />
);

export default ModalSignupComponent;