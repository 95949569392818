import React from 'react';
import { Link } from 'gatsby';
import styled from '@emotion/styled'
import dimensions from 'styles/dimensions';
import colors from 'styles/colors';
import Arrow from '../../vectors/arrow-right.svg';

const LinkContainer = styled(Link)`
  display: flex;
  align-content: center;
  align-items: center;
  margin: 3.2rem auto 0;

  p {
    transition: color 0.08s ease-in-out;
    margin-right: 1.8rem;
    color: ${colors.grey900};
    font-size: 1.8rem;
    font-weight: 600;
  }

  svg {
    transition: fill 0.08s ease-in-out;
    width: 2.8rem;
    height: 2rem;
    fill: ${colors.yellow500};
  }
  
  &:hover {
    p {
      color: ${colors.yellow500};
    }

    svg {
      fill: ${colors.yellow500};
    }
  }

  &.Arrow--footer {
    &:hover {
      p {
        color: ${colors.yellow500};
      }

      svg {
        fill: ${colors.yellow500};
      }
    }
    
    svg {
      fill: ${colors.grey500};
    }
  }

  &.Arrow--program {
    &:hover {
      p {
        color: ${colors.yellow500};
      }

      svg {
        fill: ${colors.yellow500};
      }
    }
    
    svg {
      fill: ${colors.grey500};
    }
  }

  &.Arrow--trapdoor {
    &:hover {
      p {
        color: ${colors.yellow500};
      }

      svg {
        fill: ${colors.yellow500};
      }
    }
    
    p {
      color: #fff;
    }
    
    svg {
      fill: ${colors.yellow500};
    }
  }

  @media (min-width: ${dimensions.tabletLandscapeUp}px) {
    margin-top: 4.8rem;
  }
`;

const ArrowLink = ({ text, variant, destination }) => {
  return (
    <LinkContainer
      to={destination}
      className={`${variant ? `Arrow--${variant}` : ''}`}
    >
      <p>{text}</p>
      <Arrow />
    </LinkContainer>
  );
};

export default ArrowLink;
