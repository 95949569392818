import React, { useState, useRef } from 'react';
import { window } from 'browser-monads';
import styled from '@emotion/styled'
import colors from 'styles/colors';
import classNames from 'classnames';
import ClearIcon from '../vectors/ex.svg';
import SearchIcon from '../vectors/search.svg';

const SearchBoxContainer = styled.div`
  position:relative;
  width: 100%;
  border-bottom: 1px solid ${colors.grey300};
`;

const SearchBoxForm = styled.form`
  display: flex;
`;

const SearchBoxInput = styled.input`
  width: calc(100% - 4rem);
  height: 6.4rem;
  appearance: none;
  border: 0 none;
  background: #fff;
  box-shadow: none;
  padding: 0 3.2rem;
  font-size: 1.8rem;

  ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: ${colors.grey500};
    opacity: 1; /* Firefox */
  }

  ::-ms-input-placeholder { /* Microsoft Edge */
    color: ${colors.grey500};
  }
`;

const SearchBoxClear = styled.span`
  position: absolute;
  right: 76px;
  top: 50%;
  transform: translateY(-50%);
  width: 1.6rem;
  height: 1.6rem;
  cursor: pointer;
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.12s ease-in-out;

  &.is-visible {
    visibility: visible;
    opacity: 1;
  }
  
  svg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    fill: gray;
  }
`;

const SearchBoxSubmit = styled.button`
  width: 6.4rem;
  height: 6.4rem;
  background-color: ${colors.teal600};
  transition: background 0.08s ease-in-out;

  svg {
    fill: #fff;
  }

  :active {
    background-color: ${colors.yellow500};
  }
`;

const MobileSearchBox = () => {
  const queryString = window.location.search
  const urlParams = new URLSearchParams(queryString)
  const queryParam = urlParams.get('q')

  const wrapperRef = useRef(null)
  const inputRef = useRef(null)

  const [query, setQuery] = useState(queryParam ? queryParam : "")
  const [clearVisible, setClearVisible] = useState(window.location.pathname.includes("/search"))

  const clearClasses = classNames({
    'is-visible': clearVisible,
  })

  const handleChange = (e) => {
    setQuery(e.target.value)
    setClearVisible(e.target.value.length > 0)
  }

  const handleClick = (e) => {
    if (inputRef.current.value.length < 1) {
      e.preventDefault();
    }
  }

  const handleClear = (e) => {
    e.preventDefault();
    setQuery("");
    setClearVisible(false)
    inputRef.current.focus();
  }

  return (
    <>
      <SearchBoxContainer ref={wrapperRef}>
        <SearchBoxForm action="/search" method="get" role="search">
          <SearchBoxInput ref={inputRef} type="text" id="q" name="q" placeholder="Search Leading Edge" onChange={handleChange} value={query} />

          <SearchBoxClear onClick={handleClear} value="clear" className={clearClasses}>
            <ClearIcon />
          </SearchBoxClear>

          <SearchBoxSubmit value="" type="submit" onClick={handleClick}>
            <SearchIcon />
          </SearchBoxSubmit>
        </SearchBoxForm>
      </SearchBoxContainer>
    </>
  );
}

export default MobileSearchBox;