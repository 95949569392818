import React, { useState, useEffect, useRef } from 'react';
import { window } from 'browser-monads';
import styled from '@emotion/styled'
import colors from 'styles/colors';
import dimensions from 'styles/dimensions';
import classNames from 'classnames';
import ClearIcon from '../vectors/ex.svg';
import SearchIcon from '../vectors/search.svg';

const SearchBoxContainer = styled.div`
  display: none;
  position: relative;
  margin-left: 2.77vw;

  @media(min-width: ${dimensions.tabletLandscapeUp}px) {
    display: inline-block;
  }
`

const SearchBoxForm = styled.form`
  position: relative;
  width: 4rem;
  height: 4rem;
`

const SearchBoxInput = styled.input`
  position: relative;
  width: 100%;
  height: 100%;
  appearance: none;
  border: 0 none; 
  border-radius: 2rem;
  background-color: #f3f3f3;
  font-size: 1.6rem;
  padding: 0 4rem 0 2.4rem;

  ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: ${colors.grey500};
    opacity: 1; /* Firefox */
  }

  ::-ms-input-placeholder { /* Microsoft Edge */
    color: ${colors.grey500};
  }
`

const SearchBoxBar = styled.div`
  position: absolute;
  right: 50px;
  width: 0;
  height: 4rem;
  opacity: 0;
  overflow: hidden;
  transition: width 0.24s ease-out, opacity 0.12s ease-in-out;
  
  .is-open & {
    width: 35rem;
    opacity: 1;
  }
`;

const SearchBoxClear = styled.span`
  position: absolute;
  right: 16px;
  top: 50%;
  transform: translateY(-50%);
  width: 1.2rem;
  height: 1.2rem;
  cursor: pointer;
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.12s ease-in-out;

  &.is-visible {
    visibility: visible;
    opacity: 1;
  }
  
  svg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    fill: gray;
  }
`;

const SearchBoxToggle = styled.button`
  position: absolute;
  top: 0;
  right: 0;
  width: 4rem;
  height: 4rem;
  border-radius: 2rem;
  appearance: none;
  border: 0 none;
  box-shadow: none;
  background-color: #f3f3f3;
  cursor: pointer;
  transition: background 0.12s ease-in-out;
  
  svg {
    fill: ${colors.grey700};
    transition: fill 0.12s ease-in-out;
  }
  
  &:hover {
    background-color: ${colors.orange500};

    svg {
      fill: #fff;
    }
  }
  
  .is-open & {
    background-color: ${colors.teal700};
    
    &:hover {
      background-color: ${colors.yellow500};
    }

    svg {
      fill: #fff;
    }
  }
`

const useOutsideAlerter = (ref, setOpen) => {
  useEffect(() => {
    const handleClickOutside = e => {
      if (ref.current && !ref.current.contains(e.target)) {
        setOpen(false)
      }
    }

    [`mousedown`, `touchstart`].forEach(event =>
      document.addEventListener(event, handleClickOutside)
    )
    return () =>
      [`mousedown`, `touchstart`].forEach(event =>
        document.removeEventListener(event, handleClickOutside)
      )
  }, [ref, setOpen])
}

const SearchBox = () => {
  const queryString = window.location.search
  const urlParams = new URLSearchParams(queryString)
  const queryParam = urlParams.get('q')

  const wrapperRef = useRef(null)
  const inputRef = useRef(null)

  const [query, setQuery] = useState(queryParam ? queryParam : "")
  const [open, setOpen] = useState(false)
  const [clearVisible, setClearVisible] = useState(window.location.pathname.includes("/search"))

  useOutsideAlerter(wrapperRef, setOpen)

  const searchClasses = classNames({
    'is-open': open,
  })

  const clearClasses = classNames({
    'is-visible': clearVisible,
  })

  const handleChange = (e) => {
    setQuery(e.target.value)
    setClearVisible(e.target.value.length > 0)
  }

  const handleClick = (e) => {
    const searchInput = inputRef.current;

    if (!open) {
      e.preventDefault();

      setQuery(searchInput.value)
      setOpen(true);

      searchInput.focus();
      searchInput.setSelectionRange(searchInput.value.length, searchInput.value.length);

      if (searchInput.value.length > 0) {
        setClearVisible(true);
      }
    }

    if (searchInput.value.length < 1) {
      e.preventDefault();
    }
  }

  const handleClear = (e) => {
    e.preventDefault();

    setQuery("");
    setClearVisible(false);

    inputRef.current.focus();
  }

  return (
    <>
      <SearchBoxContainer className={searchClasses} ref={wrapperRef}>
        <SearchBoxForm action="/search" method="get" role="search">
          <SearchBoxBar>
            <SearchBoxInput ref={inputRef} type="text" id="q" name="q" placeholder="Search Leading Edge" onChange={handleChange} value={query} />
            <SearchBoxClear onClick={handleClear} value="clear" className={clearClasses}>
              <ClearIcon />
            </SearchBoxClear>
          </SearchBoxBar>

          <SearchBoxToggle value="" type="submit" onClick={handleClick}>
            <SearchIcon />
          </SearchBoxToggle>
        </SearchBoxForm>
      </SearchBoxContainer>
    </>
  );
}

export default SearchBox;