import React from 'react';
import styled from '@emotion/styled'
import dimensions from 'styles/dimensions';
import { Inner } from 'styles/structure';
import colors from 'styles/colors';
import MailchimpForm from 'components/MailchimpForm';

const ContactCardContainer = styled.div`
  padding: 6.4rem 0;
  border-top: 1px solid ${colors.grey300};
`;

const ContactInner = styled(Inner)`
  @media (min-width: ${dimensions.desktopUp}px) {
    display: flex;
  }
`;

const ContactHeader = styled.header`
  & + * {
    margin-top: 3.2rem;
  }
  
  @media (min-width: ${dimensions.desktopUp}px) {
    flex: 0 1 50%;

    & + * {
      margin-top: 0;
      margin-left: 3.8rem;
    }
  }
`;

const StayConnectedHeading = styled.h3`
  color: ${colors.teal700};
  
  & + * {
    margin-top: 2.4rem;  
  }
`;

const ContactText = styled.p`
  margin-bottom: 4rem;
  max-width: 64rem;
  
  @media (min-width: ${dimensions.desktopUp}px) {
    font-size: 1.8rem;
    max-width: 40rem;
  }
`;

const ContactForm = styled.div`
  @media (min-width: ${dimensions.desktopUp}px) {
    width: 62.8rem;  
  }
`;

const ContactFooter = (props) => {
  return (
    <ContactCardContainer>
      <ContactInner>
        <ContactHeader>
          {props.heading && <StayConnectedHeading>{props.heading}</StayConnectedHeading>}

          {props.text && <ContactText>{props.text}</ContactText>}
        </ContactHeader>

        <ContactForm>
          <MailchimpForm
            variant='footer'
          />

        </ContactForm>
      </ContactInner>
    </ContactCardContainer>
  );
};

export default ContactFooter;
